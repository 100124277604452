import React from 'react';
import styles from './Aside_right.module.scss';
import Email from './Email/Email';
import Suggestions from './Suggestions/Suggestions';

const AsideRight = ({posts}) => {
    return (
        <aside className={styles.aside}>
            <Email />
            <Suggestions posts={posts} />
        </aside>
    )
};

export default AsideRight;
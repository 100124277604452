import React from 'react';
import styles from './PostDate.module.scss';

const PostDate = ({initDate, marginTop}) => {
    const pubDate = new Date(initDate.split('-').join('/')).toLocaleString('ru', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });

    return(
        <address className={styles.published} style={{marginTop: `${marginTop}px`}}>
            <div className={styles.post__author}>
            <img src="/../user.png" alt="автор" width="50" height="50" />
            <a href="https://twitter.com/stassonmars" rel="author">Стас Багрецов</a>
            </div>
            <time pubdate="pubdate" dateTime={initDate}>{pubDate}</time>
        </address>
    )
};

export default PostDate;
import React, {useEffect, useState, useRef} from 'react';
import Layout from '../components/Layout/Layout';
import AsideRight from '../components/Asides/Aside_right/Aside_right';
import ADS from '../components/Asides/ADS/Ads_left';
import styles from './Post.module.scss';
import parse, {domToReact} from 'html-react-parser';
import PostCode from '../components/PostCode/PostCode';
import SEO from '../components/seo';
import PostDate from '../components/PostDate/PostDate';
import TwoRecentPosts from '../components/TwoRecentPosts/TwoRecentPosts';
import SMMPanel from '../components/SMMPanel/SMMPanel';
import TagsOnPage from '../components/TagsOnPage/TagsOnPage';


const Post = ({pageContext}) => {

  let type = pageContext.type === 'js' ? 'javascript' : pageContext.type;
  let url = `https://www.stasonmars.ru/${type}/${pageContext.slug}`;

    const replaceCode = node => {
        if (node.name === 'pre') {
          return node.children.length > 0 && <PostCode language={getLanguage(node)}>{domToReact(getCode(node))}</PostCode>;
        }
    };

    const getLanguage = node => {
        const lang = node.attribs.class.split(" ")[1];
        if (lang != null) {
          return lang;
        }
        return null;
      };

      const getCode = node => {
        if (node.children.length > 0 && node.children[0].name === 'code') {
          return node.children[0].children;
        } else {
          return node.children;
        }
      };

      const schema = {
        "@context": "https://schema.org",
        "@type": "Article",
        "headline": pageContext.title,
        "image": pageContext.featured_media.source_url,  
        "description": pageContext.acf.seo_desc,
        "author": {
          "@type": "Person",
          "name": "Стас Багрецов"
        },  
        "publisher": {
          "@type": "Person",
          "name": "Стас Багрецов",
          "logo": {
            "@type": "ImageObject",
            "url": "https://stasonmars.ru/logo.png"
          }
        },
        "datePublished": pageContext.date,
        "keywords": pageContext.keywords,
        "mainEntityOfPage": {
            "@Type": "WebPage",
            "@id": "https://stasonmars.ru/"
        }
      }

      let parsedContent = <section className={styles.in__content}>{parse(pageContext.content, {replace: replaceCode})}</section>;

      
    return(
        <Layout>
            <SEO title={pageContext.title} description={pageContext.acf.seo_desc} keywords={pageContext.acf.keywords} schema={schema} img_smm={pageContext.featured_media.source_url} initPubdate={pageContext.acf.pub_date} url={url} />
            <ADS />
            <main className={styles.post__content}>
                <article>
                    <header className={styles.post__header}>
                        <h1>{pageContext.title}</h1>
                        <TagsOnPage tags={pageContext.acf.tags} />
                        <PostDate initDate={pageContext.acf.pub_date} marginTop={25} />
                    </header>
                {parsedContent}
                <TwoRecentPosts type={pageContext.type} />
                </article>
                
                <SMMPanel url={url} />
            </main>
            <AsideRight posts={pageContext.interesting}/>
            
        </Layout>
    )
};

export default Post;
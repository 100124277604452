import React from 'react';
import { Link } from 'gatsby';
import styles from './TagsOnPage.module.scss';

const TagsOnPage = ({tags}) => {
  const uniqueStyles = ['react', 'javascript', 'css', 'html', 'es2021'];
  return(
    <article className={styles.tags__container}>
      <img src="/../tag.png" alt="" className={styles.tags__container__image} />
      {tags.split(',').map((item, i) => {
        let tag = item.trim().toLowerCase();
        let url = encodeURIComponent(tag);
        return <Link to={`/tag/${url}`} className={`${styles.tags__container__link} ${uniqueStyles.includes(tag) ? styles[tag] : ''}`} key={i+794279}>#{tag}</Link>
      })}
    </article>
  )
};

export default TagsOnPage;
import React from 'react';
import { Link } from 'gatsby';
import styles from './Suggestions.module.scss';

const Suggestions = ({posts}) => {
       return(
        <section className={styles.suggestions__container}>
          <h3>Интересные посты:</h3>
          {posts && posts.map((post, i) => {
            let type = post.type === 'js' ? 'javascript' : post.type;
            return <Link to={`/${type}/${post.slug}`} key={i + 39078} className={styles.suggestions__container__link}>{post.title}</Link>
          })}
        </section>
       )
};

export default Suggestions;
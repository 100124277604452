import React, {useEffect, useState, useRef} from 'react';
import styles from './SMMPanel.module.scss';


export default function SMMPanel({url}) {
    const [scrollTop, setScrollTop] = useState(0);
    const [showPanel, setPanel] = useState(false);
    const socialPanel = useRef(null);


useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop);
    };

    if(scrollTop > 360) {
        setPanel(true);
        //window.removeEventListener("scroll", onScroll);
    } else {
        setPanel(false);
    }

    if(window.screen.width > 767) {
      //console.log('mobile')
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
    
  }, [scrollTop]);

  return (
    <div className={showPanel ? `${styles.post__content__social} ${styles.showPanel}` : `${styles.post__content__social}`} ref={socialPanel} >
        <a href={`https://vk.com/share.php?url=${url}`} aria-label="поделиться ссылкой в vk"><img src="../../vk.svg" alt="" width="50" height="50" /></a>
        <a href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} aria-label="поделиться ссылкой в facebook"><img src="../../fb.svg" alt="" width="50" height="50" /></a>
        <a href={`tg://msg?text=${url}`} aria-label="поделиться ссылкой в telegram"><img src="../../tg.svg" alt="" width="50" height="50" /></a>
    </div>
  )
}

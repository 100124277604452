import React, { useState } from 'react';
import styles from './EmailForm.module.scss';

const EmailForm = ({submitEmail}) => {
  const [name, nameSetter] = useState('');
  const [email, emailSetter] = useState('');

  return (
    <form onSubmit={(e) => submitEmail(e, name, email)} className={styles.email__form}>
      <label htmlFor='name'>Имя</label>
      <input id='name' type='text' value={name} onChange={(e) => nameSetter(e.target.value.trim())}/>
      <label htmlFor='email'>Email</label>
      <input id='email' type='email' value={email} onChange={(e) => emailSetter(e.target.value)} />
      <button type='submit' className={styles.email__form__submit}>Подписаться</button>
    </form>
  )
  
};

export default EmailForm;
import React, { useState, useEffect } from 'react';
import EmailForm from './EmailForm/EmailForm';
import Subscribed from './Subscribed/Subscribed';
import styles from './Email.module.scss';

const Email = () => {
  const [showForm, showFormSetter] = useState(false);
  const [message, messageSetter] = useState('');
  const [validate, validateSetter] = useState(false);

  //получаем значение из localStorage после рендеринга статики
  useEffect(() => {
    const emailIdentify = localStorage.getItem('email') ? eval(localStorage.getItem('email')) : false;
    if(emailIdentify === true) {
      messageSetter('Вы подписаны!')
    }
    showFormSetter(emailIdentify);
  }, []);

  const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validateName = name => name.length > 0 && name.split('').every(i => i !== ' ');

  const submitEmail = (e, name, email) => {
    e.preventDefault();

    console.log('EMAIL' + validateEmail(email));
    console.log(validateName(name))

    const data = {email, name};

    if(validateEmail(email) && validateName(name)) {
      console.log('ПРОШЛО')
      fetch('https://email-c23de-default-rtdb.europe-west1.firebasedatabase.app/email.json', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {'Content-Type': 'application/json'}
    }).then(res => {
      if(res.ok) {
        localStorage.setItem('email', 'true');
        messageSetter('Вы подписаны');
        showFormSetter(true);
        validateSetter(false);
      } else {
        messageSetter('Ошибка подписки');
        showFormSetter(true);
      }
    });
    } else {
      validateSetter(true);
    }
  };

  const form = !showForm ? <EmailForm submitEmail={submitEmail} /> : <Subscribed message={message} />

  return(
    <section className={styles.email__container}>
      <div className={styles.email__container__info}>
        <div className={styles.email__container__header}>
          <img src="/../email.png" alt=""/>
          <h3>Email подписка!</h3>
        </div>
        <p>А что в ней?</p>
        <ul className={styles.email__container__list}>
          <li>Интересные новости</li>
          <li>Полезные статьи</li>
          <li>Сниппеты кода</li>
          <li>Много интересного</li>
        </ul>
      </div>
      {form}
      {validate && <p style={{textAlign: 'center'}}>Нормально заполни</p>}
    </section>
  )
};

export default Email;
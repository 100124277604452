import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styles from './TwoRecentPosts.module.scss';

export default function TwoRecentPosts({ type }) {
    const { javascript, html } = useStaticQuery(graphql`
        query {
            javascript: allWordpressWpJs(limit: 2) {
                nodes {
                    title
                    slug
                }
            }
            html: allWordpressWpHtmlCss(limit: 2) {
                nodes {
                    title
                    slug
                }
            }
        }
    `);

    const renderData = type === 'js' ? javascript : html;
    const linkCat = type === 'js' ? 'javascript' : 'html-css';
    
    

    return (
        <div className={styles.latest__posts}>
            {renderData.nodes.map((item, i) => {
                const url = `/${linkCat}/${item.slug}/`
                return <Link to={url} key={i+5}>{item.title}</Link>
            })}
        </div>
    )
};

